import "vite/modulepreload-polyfill";
// imported globally in base.html:
// import "./vendor/jquery.autocomplete.js";
import "../scss/screen.scss";

function bindClicks() {
    const $document = $(document);
    const $body = $("body");

    $document.on("click", ".toggle-menu", function () {
        $body.toggleClass("open-navigation");
    });
    $document.on("click", ".toggle-search", function () {
        $body.addClass("open-search");
        $(".search-form input").focus();
    });

    $document.on("click", ".show-more", function () {
        $(this).toggleClass("hover");
    });

    $document.on("click", ".toggle-filters", function () {
        $body.toggleClass("open-filters");
    });

    $document.on("click", ".filter-form-submit", function (e) {
        e.preventDefault();
        $body.removeClass("open-filters");
    });

    $document.click(function (e) {
        const $target = $(e.target);
        if (!$target.parents(".show-more").length) {
            $(".show-more").removeClass("hover");
        }
        if (
            !$target.parents(".site-navigation").length &&
            !$target.hasClass("toggle-menu") &&
            !$target.hasClass("site-navigation")
        ) {
            if ($body.hasClass("open-navigation")) {
                $body.removeClass("open-navigation");
                e.preventDefault();
            }
        }
        if (
            !$target.parents(".block-search-filters").length &&
            !$target.hasClass("block-search-filters") &&
            !$target.hasClass("toggle-filters")
        ) {
            if ($body.hasClass("open-filters")) {
                $body.removeClass("open-filters");
                e.preventDefault();
            }
        }
        if (
            !$target.parents(".search-form").length &&
            !$target.parents(".toggle-search").length &&
            !$target.parents(".autocomplete-suggestions").length &&
            !$target.hasClass("toggle-search")
        ) {
            $body.removeClass("open-search");
        }
    });
}

function setupAutocomplete() {
    $(".autocomplete").each(function () {
        const $this = $(this);
        const serviceUrl = $this.data("url");

        $(this).autocomplete({
            serviceUrl: serviceUrl,
            noCache: true,
            deferRequestBy: 500,
            onSelect: function (suggestion) {
                window.location.href = suggestion.hyperlink;
            },
            beforeRender: function (container, suggestions) {
                if ($this.data("classname")) {
                    container.addClass($this.data("classname"));
                }
            },
        });
    });
}

function setupBackgroundImages() {
    $("[data-background-image]").each(function () {
        const $this = $(this);

        $this.css({
            "background-image": "url(" + $this.data("background-image") + ")",
        });
    });
}

function setupProductFilters() {
    const $asyncContentContainer = $("#async-content"),
        formSelector = "form.block-search-filters";

    if (!$asyncContentContainer.length) {
        return;
    }

    function applyFilters($form, pushState, queryParams) {
        if (!queryParams) {
            queryParams = $form.serialize();
        }

        $.post($form.attr("action"), queryParams).done(function (
            data,
            status,
            xhr
        ) {
            if (status === "success") {
                $asyncContentContainer.html(data);

                const currentPath = xhr.getResponseHeader("X-CurrentPath");
                if (currentPath && pushState) {
                    window.history.pushState(
                        queryParams,
                        document.title,
                        currentPath
                    );
                }
            } else {
                // something went wrong :-(
            }
        });
    }

    $(document)
        .on("submit", formSelector, function (event) {
            const $form = $(event.target).closest(formSelector);
            event.preventDefault();
            applyFilters($form, true);
        })
        .on(
            "change",
            formSelector + " .filter-item-check input",
            function (event) {
                $(event.target).closest(formSelector).submit();
            }
        );

    $asyncContentContainer.on("click", ".pagination a", function (event) {
        event.preventDefault();
        const $link = $(event.target);
        const page = $link.data("page");
        const href = $link.attr("href");
        $asyncContentContainer[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
        $.get(href).done(function (data, status, xhr) {
            if (status === "success") {
                $asyncContentContainer.html(data);

                const currentPath = xhr.getResponseHeader("X-CurrentPath");
                const queryParams = $asyncContentContainer
                    .find(formSelector)
                    .serialize();
                window.history.pushState(
                    queryParams + "&page=" + page,
                    document.title,
                    currentPath
                );
            } else {
                // something went wrong :-(
            }
        });
    });

    window.addEventListener("popstate", function (event) {
        const $form = $(formSelector);
        if ($form) {
            applyFilters($form, false, event.state);
        }
    });

    $(formSelector).each(function (i, form) {
        window.history.replaceState($(form).serialize(), document.title);
    });
}

$(function () {
    bindClicks();
    setupAutocomplete();
    setupProductFilters();
    setupBackgroundImages();
});
